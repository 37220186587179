<template>
    <div class="inner_pages container">
        <div class="respective_content">
            <div class="section_title">
                Welcome, Coach! What would you like to do?
            </div>
            <div class="featured_list">
                <ul>
                    <li>
                        <router-link :to="{ name: 'AccountSetup' }" class="icon"><img src="@/assets/images/account.svg" alt="Account"></router-link>
                        <!-- <router-link :to="{ name: 'SetupGuideIndex', query: { step: 1 } }" class="icon"><img src="@/assets/images/account.svg" alt="Account"></router-link> -->
                        <div class="item_info">
                            <h3><router-link :to="{ name: 'AccountSetup' }">Set Up My Account</router-link></h3>
                            <!-- <h3><router-link :to="{ name: 'SetupGuideIndex', query: { step: 1 } }">Set Up My Account</router-link></h3> -->
                            <h5>Optimize your setup for top-notch results.</h5>
                        </div>
                    </li>
                    <li>
                        <router-link :to="{ name: 'SetupGuideIndex', query: { step: 2 } }" class="icon"><img src="@/assets/images/speedometer.svg" alt="Score"></router-link>
                        <div class="item_info">
                            <h3><router-link :to="{ name: 'SetupGuideIndex', query: { step: 2 } }">Get My Thrive Score<sup>TM</sup></router-link></h3>
                            <h5>Evaluate your business to prioritize efforts.</h5>
                        </div>
                    </li>
                    <li>
                        <a href="https://thrivecoach.trafft.com/services/thrive-call" target="_blank" class="icon"><img src="@/assets/images/phone_call.svg" alt="Phone"></a>
                        <div class="item_info">
                            <h3><a href="https://thrivecoach.trafft.com/services/thrive-call" target="_blank">Book My Thrive Call<sup>TM</sup></a></h3>
                            <h5>Schedule your introductory session with a Thrive team member.</h5>
                        </div>
                    </li>
                    <li>
                        <a @click="contentImportWizard = true;" class="icon"><img src="@/assets/images/log_in.svg" alt="Login"></a>
                        <div class="item_info">
                            <h3><a @click="contentImportWizard = true;">Import A Content File</a></h3>
                            <h5>Easily add DFY content you've acquired.</h5>
                        </div>
                    </li>
                    <li>
                        <a href="https://help.thrivecoach.io/" target="_blank" class="icon"><img src="@/assets/images/book.svg" alt="Book"></a>
                        <div class="item_info">
                            <h3><a href="https://help.thrivecoach.io/" target="_blank">Visit Help Center</a></h3>
                            <h5>Find 24/7 resources and support to flourish.</h5>
                        </div>
                    </li>
                    <li>
                        <a href="https://academy.thrivecoach.io/" target="_blank" class="icon"><img src="@/assets/images/graduation.svg" alt="Graduation"></a>
                        <div class="item_info">
                            <h3><a href="https://academy.thrivecoach.io/" target="_blank">Thrive Academy<sup>TM</sup></a></h3>
                            <h5>Unlock exclusive training, perks, and recognition.</h5>
                        </div>
                    </li>
                    <li>
                        <a @click="marketplacePopup = true;" class="icon"><img src="@/assets/images/store.svg" alt="Store"></a>
                        <div class="item_info">
                            <h3><a @click="marketplacePopup = true;">Thrive Marketplace<sup>TM</sup></a></h3>
                            <h5>Discover and buy ready-made content and programs!</h5>
                        </div>
                    </li>
                    <li>
                        <a href="https://thrivecoach.link/feEsicYH" target="_blank" class="icon"><img src="@/assets/images/agency.svg" alt="Agency"></a>
                        <div class="item_info">
                            <h3><a href="https://thrivecoach.link/feEsicYH" target="_blank">Insiders' Circle<sup>TM</sup></a></h3>
                            <h5>Join the CEO's private portal. Free bonuses and giveaways!</h5>
                        </div>
                    </li>
                    <li>
                        <a @click="thriveOffice = true;" class="icon"><img src="@/assets/images/sun.svg" alt="sun"></a>
                        <div class="item_info">
                            <h3><a @click="thriveOffice = true;">Thrive Office Hours<sup>TM</sup></a></h3>
                            <h5>Join our daily live support calls weekdays at 3pm ET.</h5>
                        </div>
                    </li>
                </ul>

                <Transition duration="550" name="nested">
                    <ul v-if="advanced">
                        <li>
                            <router-link :to="{ name: 'AdminSettings', params: { modal: 'accountSecondaryLocation' } }" class="icon"><img src="@/assets/images/building.svg" alt="Building"></router-link>
                            <div class="item_info">
                                <h3><router-link :to="{ name: 'AdminSettings', params: { modal: 'accountSecondaryLocation' } }">Add Sub Accounts</router-link></h3>
                                <h5>Expand effortlessly with additional accounts.</h5>
                            </div>
                        </li>
                        <li>
                            <router-link :to="{ name: 'AdminSettings', params: { modal: 'accountUser' } }" class="icon"><img src="@/assets/images/coaches.svg" alt="Coaches"></router-link>
                            <div class="item_info">
                                <h3><router-link :to="{ name: 'AdminSettings', params: { modal: 'accountUser' } }">Add Coaches</router-link></h3>
                                <h5>Enhance your team by adding coaches.</h5>
                            </div>
                        </li>
                        <li>
                            <a @click="showUpcomingMessage" class="icon"><img src="@/assets/images/integration.svg" alt="integration"></a>
                            <div class="item_info">
                                <h3><a @click="showUpcomingMessage">Add Integrations</a></h3>
                                <h5>Customize with integrations - consult us for details.</h5>
                            </div>
                        </li>
                    </ul>
                </Transition>

                <button class="advance_btn" @click="advanced = !advanced">{{advanced ? 'Minimize' : 'Advanced'}}</button>
            </div>
        </div>
        <content-import-wizard v-model="contentImportWizard" :toggle-history="openOptinModal" />
        <marketplace-component v-model="marketplace"></marketplace-component>
        <thrive-office v-model="thriveOffice" />
      <PopUpModal v-model="marketplacePopup">
        <div class="popup-container">
          <div class="left">
            <h2>New Thrive Marketplace</h2>
            <p>Coming Soon!</p>
            <button type="button" class="add_btn pointer" @click="marketplacePopup = false">
              Got it!
            </button>
          </div>
          <div class="right">
            <img src="@/assets/images/marketplace_store.svg">
          </div>
        </div>
      </PopUpModal>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Helper from '@/utils/Helper'
import { defineAsyncComponent } from 'vue'
import PopUpModal from '@/components/PopUpModal.vue'

const ContentImportWizard = defineAsyncComponent(() => import('@/pages/apps/components/ContentImportWizard'))
const MarketplaceComponent = defineAsyncComponent(() => import('@/components/Marketplace'))
const ThriveOffice = defineAsyncComponent(() => import('@/pages/home/components/ThriveOffice'))

export default {
    name: "Home Index",

    data () {
        return{
            advanced: false,
            contentImportWizard: false,
            marketplace: false,
            thriveOffice: false,
            marketplacePopup: false
        }
    },

    components: {
      PopUpModal,
        ContentImportWizard,
        MarketplaceComponent,
        ThriveOffice
    },

    methods:{
        openOptinModal (type) {
            const vm = this;

            vm[type] = true;
        },

        showUpcomingMessage () {
            Swal.fire(Helper.swalWarningOptions('Coming Soon!'))
        },
    }
}

</script>

<style scoped>
.popup-container {
  display: flex;
  align-items: center;
  height: 250px;
  width: 500px;
}
.popup-container .left {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-container .left h2 {
  margin-bottom: 10px;
}
.popup-container .right img {
  width: 200px;
}
.add_btn{
  height: 35px;
  background: #2f7eed;
  border-radius: 4px;
  padding: 0 15px;
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width: 200px;
}

.respective_content{
    padding-left: 0;
}
.container{
    max-width: 1300px;
    margin: 0 auto;
}
.section_title{
    font-size: 28px;
    line-height: 38px;
    font-weight: 500;
    color: #121525;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.section_title img{
    margin-bottom: 15px;
    max-height: 40px;
    height: auto;
}
.featured_list{
    background: #fff;
    border-radius: 5px;
    padding: 25px 15px;
    margin: 40px 0;
}
.featured_list ul{
    display: flex;
    flex-wrap: wrap;
}
.featured_list ul li{
    padding: 30px 25px;
    width: 33.333%;
    display: flex;
    align-items: flex-start;
}
.featured_list ul li .icon{
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 10px;
    background: linear-gradient(to right, #2FB4ED, #2f7eed);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 2px;
}
.featured_list ul li .icon img{
    max-height: 32px;
}
.featured_list ul li .item_info{
    flex: 1 1 auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 20px;
}
.featured_list ul li .item_info h3{
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 5px;
}
.featured_list ul li .item_info h3 a{   
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
.featured_list ul li .item_info h3 sup{
    font-size: 9px;
    line-height: 12px;
    font-weight: 500;
    padding-left: 2px;
}
.featured_list ul li .item_info h5{
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
    color: #7a7a7a;
}
.advance_btn{
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #5a5a5a;
    background: #e9e9e9;
    border: 1px solid #f5f5f5;
    padding: 7px 15px;
    border-radius: 15px;
    margin: 40px 0 10px 0;
    cursor: pointer;
}

@media(max-width: 1350px){
    .featured_list ul li{
        padding: 25px 20px;
    }
    .featured_list ul li .item_info h3{
        font-size: 16px;
        line-height: 21px;
    }
    .featured_list ul li .item_info h5{
        font-size: 12px;
        line-height: 18px;
    }
}
@media(max-width: 1199px){
    .section_title{
        font-size: 25px;
        line-height: 34px;
    }
    .featured_list ul li{
        padding: 20px 15px;
    }
    .featured_list ul li .item_info h3{
        font-size: 15px;
        line-height: 20px;
    }
    .featured_list ul li .item_info h5{
        font-size: 11px;
        line-height: 16px;
    }
    .featured_list ul li .icon{
        width: 52px;
        height: 52px;
    }
    .featured_list ul li .icon img{
        max-height: 25px;
    }
    .featured_list ul li .item_info{
        padding: 0 0 0 15px;
    }
}
@media(max-width: 991px){
    .container{
        max-width: 750px;
    }
    .featured_list ul li{
        width: 50%;
    }
}
@media(max-width: 599px){
    .container{
        max-width: 375px;
    }
    .featured_list ul li{
        width: 100%;
    }
}
</style>