<template>
    <div class="preview_area show_sidebar">
        <div class="global_setting">
            <router-link :to="{ name: 'HomeIndex' }" class="close_btn"><i class="fas fa-long-arrow-alt-left"></i></router-link>
            <div class="toolbar wide" v-if="step == 1">
                <div class="close_btn" @click="toggleSectionbar"><img src="@/assets/images/bar.svg"></div>
                <ul>
                    <li v-for="(video, v) in videos" :key="v" :class="videoIndex === v && !video.completed ? 'active' : video.completed ? 'watched' : 'queue'" @click="(video.completed || v == lastVideoIndex) ? selectVideo(v) : ''">
                        <span>
                            <i v-if="videoIndex === videos.length - 1" :class="`fas fa-${video.status == 'play' ? 'pause' : video.status}`"></i>
                            <i v-else :class="`fas fa-${videoIndex === v && !video.completed ? 'pause' : video.status}`"></i>
                        </span>
                        {{ video.title }}
                    </li>
                </ul>
                <div class="download_section">
                    <img src="@/assets/images/guide.png" alt="">
                    <a href="https://storage.googleapis.com/thrivecoach/pdf/Thrive%20Supersystem%20Phase%201.pdf" target="_blank" download="Thrive Supersystem Phase 1.pdf">Download The Guide</a>
                </div>
            </div>
            <div class="toolbar quest_section" v-if="step == 2">
                <div class="close_btn" @click="toggleSectionbar"><img src="@/assets/images/bar.svg"></div>
                <ul :style="`--var-progress: ${(100 / questions.length) * completedQuestion}%;`">
                    <li v-for="(category, c) of Object.keys(categories)" :class="c == 0 ? 'active' : (completedCategories >= c || (currentProgress.categories && currentProgress.categories[category] && currentProgress.categories[category].completed)) ? 'watched' : 'queue'" :key="c">
                        <span><i :class="`fas fa-${(completedCategories > c || (currentProgress.categories && currentProgress.categories[category] && currentProgress.categories[category].completed)) ? 'check' : completedCategories == c ? 'question' : 'exclamation'}`"></i></span>
                        {{ category }}
                        <ul class="sub_list">
                            <li v-for="(subCat, sc) of categories[category]" :key="sc">{{ subCat.sub_category }}</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="tabs_content" :class="{'light_bg' : step == 3}">
                <button type="button" class="toggle_bar" @click="toggleSectionbar" v-if="step < 3"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <button type="button" class="stat_bar" @click="show_progress = !show_progress;" v-if="step > 1"><img src="@/assets/images/stats.svg" alt=""></button>
                <div class="content_area">
                    <div class="section_container" v-if="step == 1">
                        <template v-for="(video, v) in videos" :key="v">
                            <div v-if="videoIndex === v" class="inner_container">
                                <template v-if="video.link || (!video.link && video.childrens.length == 0)">
                                    <h3 class="guide_title">{{ video.title }}</h3>
                                    <div style="position: relative; padding-bottom: 56.25%; height: 0;" v-if="video.link">
                                        <iframe :src="`${video.link}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                    </div>
                                    <div style="position: relative; padding-bottom: 56.25%; height: 0; text-align:center;" v-else>
                                        <h1>Coming Soon!</h1>
                                    </div>
                                </template>
                                <template v-if="video.childrens.length">
                                    <div v-for="(child, c) of video.childrens" :key="c" class="child_item">
                                        <h3 class="guide_title">{{ child.title }}</h3>
                                        <div style="position: relative; padding-bottom: 56.25%; height: 0;" v-if="child.link">
                                            <iframe :src="child.link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                        </div>
                                        <div style="position: relative; padding-bottom: 56.25%; height: 0; text-align:center;" v-else>
                                            <h1>Coming Soon!</h1>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div class="recommendation">Opening a second browser tab is recommended</div>
                        <div class="action_area">
                            <button type="button" class="next_btn" v-if="!videos[videoIndex] || (videos[videoIndex] && !videos[videoIndex].completed)" @click="nextVideoStep()">Mark Complete <i class="fas fa-long-arrow-alt-right"></i></button>
                            <button type="button" class="next_btn" v-if="videos[videoIndex] && videos[videoIndex].completed && !endedAccountSetup" @click="nextVideoStep()">Completed <i class="fas fa-long-arrow-alt-right"></i></button>
                            <button type="button" class="next_btn" v-if="endedAccountSetup" @click="step = 2">Start Assessment <i class="fas fa-long-arrow-alt-right"></i></button>
                        </div>
                    </div>
                    <div class="section_container" v-if="step == 2">
                        <div class="step_number" v-if="(currentProgress.is_completed == 0 && !endedAssessment) || (currentProgress.is_completed == 1 && endedAssessment)">{{ questionStep }}/{{ questions.length }}</div>
                        <div v-if="questions.length">
                            <flow-form v-show="(currentProgress.is_completed == 0 && !endedAssessment) || (currentProgress.is_completed == 1 && endedAssessment)" ref="questions-wizard" v-bind:questions="questions" v-bind:language="language" v-on:answer="handleQuestionAnswer" v-on:complete="handleQuestionComplete" v-on:step="handleQuestionStep" v-bind:navigation="showFormNavigation"></flow-form>
                        </div>
                        <div v-show="currentProgress.is_completed == 1 && !endedAssessment" class="thrive-score">
                              <h2>You have successfully submitted your assessment!</h2>
                              <button type="button" class="back_btn mt-5" @click="handleRetakeAssesment()">
                                  <span v-if="retakeLoader">
                                      &nbsp; Initializing <i class="fas fa-spin fa-spinner" style="top:35%;"></i>
                                  </span>
                                  <span v-else>
                                      Retake Assessment <i class="fas fa-long-arrow-alt-left"></i>
                                  </span>
                              </button>
                        </div>
                        <div class="action_area" v-if="step == 2 && ((currentProgress.is_completed == 0 && !endedAssessment) || (currentProgress.is_completed == 1 && endedAssessment))">
                            <button type="button" class="next_btn" v-if="!scoreBtn && !endedAssessment" @click="step = 3">
                                Save &amp; Exit <i class="fas fa-long-arrow-alt-right"></i>
                            </button>
                            <button type="button" class="back_btn" v-if="endedAssessment" @click="handleRetakeAssesment()">
                                <span v-if="retakeLoader">
                                    &nbsp; Initializing <i class="fas fa-spin fa-spinner" style="top:35%;"></i>
                                </span>
                                <span v-else>
                                    Retake Assessment <i class="fas fa-long-arrow-alt-left"></i>
                                </span>
                            </button>
                            <button type="button" class="next_btn" v-if="scoreBtn" @click="getScore()">
                                Get My Score
                            </button>
                            <button type="button" class="next_btn" v-if="endedAssessment" @click="step = 3; endedAssessment = false; scoreBtn = false;">
                                Get Action Plan
                            </button>
                        </div>
                    </div>
                    <div class="section_container" v-if="step == 3">
                        <div class="session_content">
                            <h4 class="guide_title">Schedule Your Thrive Session And Get Your 14-Day Personal Action Plan!</h4>
                            <div class="frame_wpr">
                                <iframe src="https://calendly.com/thrivecoachsuccess/thrive-call?embed_domain=calendly-embed.com&amp;embed_type=Inline&amp;primary_color=844cb0" width="100%" height="100%" frameborder="0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile_status" :class="{'show' : show_progress}" v-if="step == 2">
                <button class="close" @click="show_progress = false;"><i class="fas fa-times"></i></button>
                <div class="time">Estimated Time: 8 mintues</div>
                <div class="progress-circle">
                    <circle-progress :size="170" :percent="showQuestionsScore ? currentProgress.score : 0" :stroke-width="8" :color="scoreTag && colors[scoreTag] ? colors[scoreTag] : '#f2a31d'" empty-color="#f4f4f4" />
                </div>
                <div class="score-wrapper" v-if="currentProgress.score && showQuestionsScore">
                    <div class="score-card" v-for="(tag, index) of Object.keys(colors)" :key="index">
                        <div class="score-swatches" :style="`background: ${colors[tag]}`"></div><span>{{ tag }} ({{ getScoreValues(tag) }})</span>
                    </div>
                </div>
                <div v-if="currentProgress.score && showQuestionsScore" class="score-board" :style="`background: ${scoreTag && colors[scoreTag] ? colors[scoreTag] : '#f2a31d'}`">
                    <h3>Your Thrive Score</h3>
                    <h6>Your business is : <span>{{ scoreTag }}</span></h6>
                    <h5 v-if="showQuestionsScore && scoreTag === 'Thriving'">Based on your Thrive Score your business is thriving in all areas. You are highly regarded in the market, you enjoy abundant leads and sales, and you are growing like a weed. Keep it up!</h5>
                    <h5 v-if="showQuestionsScore && scoreTag === 'Healthy'">Based on your Thrive Score your business is enjoying a healthy amount of market awareness, new leads, abundant sales, and good retention. Small improvements will help you achieve optimum scalability.</h5>
                    <h5 v-if="showQuestionsScore && scoreTag === 'Average'">Based on your Thrive Score your business is average when it comes to getting seen, making sales, keeping clients, and scaling. You can expect 0-5% growth and an average return from your business.</h5>
                    <h5 v-if="showQuestionsScore && scoreTag === 'Unhealthy'">Based on your Thrive Score your business is at risk of increased churn, decreased sales, and an overall struggle to maintain stability.</h5>
                    <h5 v-if="showQuestionsScore && scoreTag === 'Dying'">Based on your Thrive Score you can expect a downward trend in your lead generation, sales, and program delivery systems that could lead to fatal outcomes for your business</h5>
                    <h5 v-if="showQuestionsScore && scoreTag === 'Fatal'">Based on your Thrive Score it is urgent that you start repairing broken areas of your business to avoid the inevitable collapse of your company and your inability to scale.</h5>
                </div>
                <div v-else class="mb-3">
                    <h3>Continue Your Setup</h3>
                    <h5>Next, take your Thrive Assessment and get your 14-day personal Thrive Plan</h5>
                    <ol>
                        <li>Answer the questions</li>
                        <li>Get your Thrive Score</li>
                    </ol>
                </div>
                <div>
                    <ul>
                        <li v-for="(scoreObj, category) of currentProgress.categories" :key="category">
                            <h5>{{ category }}</h5>
                            <div class="cont">
                                <div>
                                    <h6 v-for="(subCategoryScore, subCategory) of scoreObj.sub_categories" :key="subCategory">{{ subCategory }}: <span>{{ showQuestionsScore ? subCategoryScore : 0 }}%</span></h6>
                                </div>
                                <circle-progress :size="60" :percent="showQuestionsScore ? scoreObj.score : 0" :stroke-width="12" :color="colors[getScoreTag(scoreObj.score)]" empty-color="#f4f4f4" />
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="history">
                    <h3>History</h3>
                    <ul v-if="histories.length">
                        <li v-for="(history, h) of histories" :key="h">
                          {{ moment(history.updated_at).format('ll') }}
                          <circle-progress :size="30" :percent="history.score ? history.score : 0" :stroke-width="10" :color="colors[getScoreTag(history.score)]" empty-color="#f4f4f4" />
                          <i class="fa fa-search pointer" @click="handleViewProgress(history.id)"></i>
                        </li>
                    </ul>
                    <ul v-else>
                        <li>No records found</li>
                    </ul>
                </div>
            </div>
            <div class="profile_status" :class="{'show' : show_progress}" v-if="step == 3">
                <button class="close" @click="show_progress = false;"><i class="fas fa-times"></i></button>
                <div class="time">Estimated Call Time: 30 mintues</div>
                <div class="progress-circle">
                    <circle-progress :size="170" :percent="currentProgress.score" :stroke-width="8" :color="scoreTag && colors[scoreTag] ? colors[scoreTag] : '#f2a31d'" empty-color="#f4f4f4" />
                </div>
                <div>
                    <h3>Almost Done!</h3>
                    <h5>Just add the call to your calendar and show up ready for action. We got your back!</h5>
                    <h4>Next Steps...</h4>
                    <ol>
                        <li>Join our <a href="https://www.facebook.com/groups/onboardme" target="_blank" class="setup_link">facebook</a> group</li>
                        <li>Check out <a href="https://academy.thrivecoach.io" target="_blank" class="setup_link">Thrive Academy</a></li>
                        <li>Open our onoarding emails</li>
                    </ol>
                    <p>We hold new member onboarding session every week. Details will posted in the facebook group!</p>
                </div>
            </div>
        </div>
        <history-component v-model="showHistory" />
    </div>
</template>

<script>
    import { FlowForm, QuestionModel, QuestionType, ChoiceOption, LanguageModel } from '@ditdot-dev/vue-flow-form'
    import { mapState, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const CircleProgress = defineAsyncComponent(() => import('@/components/CircleProgress'))
    const HistoryComponent = defineAsyncComponent(() => import('@/pages/setup-guide/History'))

    import '@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css'
    import '@ditdot-dev/vue-flow-form/dist/vue-flow-form.css'

    import moment from 'moment'

    export default {
        name: 'Setup Guide',

        data () {
            return {
                step: 1,
                videoIndex: 0,
                endedAccountSetup: false,
                isCompletedVideo: false,
                showQuestionsScore: false,
                retakeLoader: false,
                showHistory: false,
                showFormNavigation: true,
                videoProgress: 0,
                videos:[],
                categories:[],
                questions:[],
                scoreBtn: false,
                score: 0,
                scoreTag: '',
                questionStep: 1,
                completedCategories: 0,
                scrollTimer:null,
                language: new LanguageModel({}),
                endedAssessment: false,
                completedQuestion: 0,
                colors: {
                    Thriving: '#50A75E',
                    Healthy: '#2D80ED',
                    Average: '#F2A32F',
                    Unhealthy: '#ECD72C',
                    Dying: '#C13F52',
                    Fatal: '#1B2437',
                },
                moment,
                lastVideoIndex: 0,
                show_progress: false,
            }
        },

        components: {
            FlowForm,
            CircleProgress,
            HistoryComponent,
        },

        watch: {
            setupGuideVideos (setupGuideVideos) {
                const vm = this;

                vm.videos = JSON.parse(JSON.stringify(setupGuideVideos));
                vm.calculateVideoProgress();

                vm.lastVideoIndex = setupGuideVideos.findIndex(({completed}) => completed == null);

                if (vm.lastVideoIndex >= 0) {
                    vm.videoIndex     = vm.lastVideoIndex ? vm.lastVideoIndex : 0;
                }
            },

            setupGuideQuestions (setupGuideQuestions) {
                const vm = this;

                vm.categories = JSON.parse(JSON.stringify(setupGuideQuestions.categories));
                vm.initQuestions(setupGuideQuestions.questions);
            },

            questionStep (step) {
                const vm = this;

                if (vm.completedQuestion < step) {
                    vm.completedQuestion = step;
                }
            },

            currentProgress (currentProgress) {
                const vm = this;
                const completed = Object.values(currentProgress.answers).filter((answer) => answer !== null);

                vm.completedQuestion = completed.length;
                vm.initProgressBar();

                if (currentProgress.is_completed == 1) {
                    vm.showQuestionsScore = true;
                }
            },

            step () {
                const vm = this;

                vm.initProgressBar();
            },

            videoIndex (index) {
                const vm = this;

                if (index < 0) {
                    vm.videoIndex = 0;
                }

                if (index == 7 && vm.videos[index] && vm.videos[index].completed) {
                    vm.endedAccountSetup = true;
                } else {
                    vm.endedAccountSetup = false;
                }
            }
        },

        computed: mapState({
            setupGuideVideos: state => state.setupGuideModule.videos,
            setupGuideQuestions: state => state.setupGuideModule.questions,
            currentProgress: state => state.setupGuideModule.currentProgress,
            histories: state => state.setupGuideModule.histories,
        }),

        mounted () {
            const vm = this;

            vm.step = vm.$route.query.step ? vm.$route.query.step : 1;

            vm.showQuestionsScore = false;
            vm.getCurrentProgress();
            vm.getHistories().then((result) => {
                if (result) {
                    if (vm.histories.length == 0) {
                        vm.handleRetakeAssesment();
                    }
                }
            });

            vm.getVideos();

            if (vm.setupGuideQuestions.length == 0) {
                vm.getQuestions();
            } else {
                vm.categories = JSON.parse(JSON.stringify(vm.setupGuideQuestions.categories));
                vm.initQuestions(vm.setupGuideQuestions.questions);
            }

            vm.initProgressBar();
            vm.calculateVideoProgress();
        },

        methods: {
            ...mapActions({
                getVideos: 'setupGuideModule/getVideos',
                getQuestions: 'setupGuideModule/getQuestions',
                saveQuestionAnswer: 'setupGuideModule/saveQuestionAnswer',
                getCurrentProgress: 'setupGuideModule/getCurrentProgress',
                getProgress: 'setupGuideModule/getProgress',
                markVideoComplete: 'setupGuideModule/markVideoComplete',
                finishQuestions: 'setupGuideModule/finishQuestions',
                getHistories: 'setupGuideModule/getHistories',
                retakeAssesment: 'setupGuideModule/retakeAssesment',
            }),

            initQuestions (questions) {
                const vm = this;
                const questionsArray = [];

                questions.forEach((question) => {
                    const optionsArray = [];

                    if (question.options && question.options.length) {
                        question.options.forEach((option) => {
                            optionsArray.push(
                                new ChoiceOption({
                                    label: option.label,
                                    value: option.id,
                                    score: option.score,
                                }),
                            );
                        });
                    }

                    questionsArray.push(
                        new QuestionModel({
                            id: question.id,
                            title: question.title,
                            helpTextShow: false,
                            type: QuestionType[question.type],
                            multiple: false,
                            allowOther: false,
                            required: question.required,
                            options: optionsArray,
                            nextStepOnAnswer: true,
                            answer:vm.currentProgress.answers[question.id],
                        })
                    );
                });

                vm.questions = questionsArray;
            },

            backVideoStep () {
                const vm = this;

                vm.videoIndex = vm.videoIndex - 1;
            },

            nextVideoStep () {
                const vm = this;

                vm.markVideoComplete({ video_id: vm.videos[vm.videoIndex].id });

                if (vm.videoIndex == 7) {
                    vm.videos[vm.videoIndex].status = 'check';
                    vm.endedAccountSetup = true;
                } else {
                    vm.videoIndex += 1;
                    vm.videos[vm.videoIndex - 1].status = 'check';
                    vm.isCompletedVideo = false;
                }
            },

            selectVideo (index) {
                const vm = this;

                vm.videoIndex = index;
            },

            calculateVideoProgress () {
                const vm = this;

                const completed = vm.videos.filter((video) => video.completed != null).length;

                vm.videoProgress = completed ? Math.round((completed * 100) / vm.videos.length) : 0;
                vm.initProgressBar();
            },

            redirectToSettings () {
                const vm    = this;
                const route = vm.$router.resolve({ name: 'AdminSettings' });

                window.open(route.href, '_blank');
                vm.isCompletedVideo = true;
            },

            handleQuestionScroll (e) {
                const vm = this;

                if (vm.scrollTimer) {
                    clearTimeout(vm.scrollTimer);
                }

                vm.scrollTimer = setTimeout(() => {
                    const next = document.querySelector('.vff-footer .f-nav .f-next');
                    const previous = document.querySelector('.vff-footer .f-nav .f-prev');

                    if (e.deltaY > 0) {
                        next.click();
                    } else {
                        previous.click();
                    }
                }, 300);
            },

            handleQuestionAnswer (answer) {
                const vm = this;

                vm.saveQuestionAnswer({ question_id: answer.id, answer: answer.answer, batch_id: vm.currentProgress.batch_id });
            },

            handleQuestionComplete (completed) {
                const vm = this;

                if (completed) {
                    vm.scoreBtn = true;
                    vm.showFormNavigation = false;
                }
            },

            handleQuestionStep (id, question) {
                const vm = this;

                if (question) {
                    vm.questionStep = question.index + 1;
                }
            },

            getScore () {
                const vm = this;

                vm.finishQuestions().then((result) => {
                    if (result) {
                        vm.scoreBtn = false;
                        vm.endedAssessment = true;
                        vm.showQuestionsScore = true;
                    }
                });
            },

            initProgressBar () {
                const vm = this;

                if (vm.currentProgress.score > 90) {
                    vm.scoreTag = 'Thriving'
                }

                if (vm.currentProgress.score >= 80 && vm.currentProgress.score <= 90) {
                    vm.scoreTag = 'Healthy'
                }

                if (vm.currentProgress.score > 65 && vm.currentProgress.score < 80) {
                    vm.scoreTag = 'Average'
                }

                if (vm.currentProgress.score >= 50 && vm.currentProgress.score <= 65) {
                    vm.scoreTag = 'Unhealthy'
                }

                if (vm.currentProgress.score >= 35 && vm.currentProgress.score < 50) {
                    vm.scoreTag = 'Dying'
                }

                if (vm.currentProgress.score < 35) {
                    vm.scoreTag = 'Fatal'
                }
            },

            getScoreTag (score) {
                const vm = this;
                let scoreTag = 'Fatal';

                if (score > 90) {
                    scoreTag = 'Thriving'
                }

                if (score >= 80 && score <= 90) {
                    scoreTag = 'Healthy'
                }

                if (score > 65 && score < 80) {
                    scoreTag = 'Average'
                }

                if (score >= 50 && score <= 65) {
                    scoreTag = 'Unhealthy'
                }

                if (score >= 35 && score < 50) {
                    scoreTag = 'Dying'
                }

                if (score < 35) {
                    scoreTag = 'Fatal'
                }

                return scoreTag;
            },

            handleViewProgress (batch) {
                const vm = this;

                vm.getProgress(batch);
                vm.showHistory = true;
            },

            handleRetakeAssesment () {
                const vm = this;
                vm.retakeLoader = true;

                vm.retakeAssesment().then((result) => {
                    if (result) {
                        vm.scoreBtn = false;
                        vm.showQuestionsScore = false;
                        vm.endedAssessment = false;

                        setTimeout(function () {
                            const form = vm.$refs['questions-wizard'];

                            if (form) {
                                form.reset();
                            }
                        }, 10);
                    }

                    vm.retakeLoader = false;
                })
            },

            getScoreValues (tag) {
                const scores = {
                                    Thriving: '91-100',
                                    Healthy: '80-90',
                                    Average: '66-79',
                                    Unhealthy: '50-65',
                                    Dying: '35-49',
                                    Fatal: '0-34',
                                };

                return scores[tag];
            },

            toggleSectionbar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },
        },
    }
</script>

<style scoped>
    .global_setting .tabs_content{
        background: #f5f5f5;
        overflow: hidden;
    }
    .global_setting .tabs_content.light_bg{
        background: #fff;
    }
    .toolbar {
        height: 100vh;
        overflow-y: scroll;
        margin-left: 10px;
        padding: 70px 10px 20px 5px;
        background: #fff;
        /* border-right: 1px solid #e9e9e9; */
        width: 220px;
        border-radius: 5px 5px 0 0;
    }
    .toolbar.wide {
        width: 280px;
        padding: 70px 25px 20px 25px;
        margin-left: 0;
    }

    .toolbar::-webkit-scrollbar {
        display: none;
    }
    .toolbar ul{
        margin-bottom: 50px;
    }
    .toolbar li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 0 0 25px 0;
        cursor: pointer;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }

    .toolbar.quest_section ul {
        position: relative;
    }

    .toolbar.quest_section>ul:before {
        content: '';
        position: absolute;
        left: 11px;
        top: 0;
        bottom: 0;
        border-left: 2px solid #e9e9e9;
        transition: all 0.3s ease-in-out;
    }

    .toolbar.quest_section>ul:after {
        content: '';
        position: absolute;
        left: 11px;
        top: 0;
        height: var(--var-progress);
        border-left: 2px solid #a1c8ff;
        transition: all 0.3s ease-in-out;
    }

    .toolbar.quest_section li {
        padding: 0;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
    }

    .toolbar.quest_section li:before {
        display: none;
    }

    .toolbar li:last-child {
        padding-bottom: 0;
    }

    /* .toolbar li:before {
        content: '';
        position: absolute;
        left: 13px;
        top: 0;
        bottom: 0;
        border-left: 2px solid #e9e9e9;
        transition: all 0.3s ease-in-out;
    } */

    .toolbar li.watched:before {
        border-color: #a1c8ff;
    }

    .toolbar li span {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        border-radius: 50%;
        /* border: 1px solid #e9e9e9; */
        background: #fff;
        color: #5a5a5a;
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-right: 10px;
        z-index: 1;
    }

    .toolbar li span i {
        position: relative;
        z-index: 2;
    }
    .toolbar li span i.fa-play {
        margin-left: 2px;
    }

    .toolbar li.watched span,
    .toolbar li.active span {
        border-color: #a1c8ff;
        color: #fff;
    }

    .toolbar li span:after {
        background: #e9e9e9;
        position: absolute;
        content: '';
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        border-radius: 50%;
    }

    .toolbar li.watched span:after,
    .toolbar li.active span:after {
        background: #2f7eed;
    }

    .toolbar li .sub_list {
        width: 100%;
        margin: 5px 0 15px 42px;
    }

    .toolbar li .sub_list li {
        padding: 7px 0;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .toolbar li .sub_list li:before {
        display: none;
    }
    .toolbar .download_section img{
        max-width: 100%;
        height: auto;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .toolbar .download_section a{
        display: block;
        padding: 10px 15px;
        border: 1px solid #999;
        border-radius: 5px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
        text-align: center;
        text-decoration: none;
    }

    .screen_wpr {
        max-width: 600px;
        width: 100%;
        padding: 15px 10px 10px 10px;
        margin: 0 auto;
    }

    .section_container {
        max-width: 1200px;
        padding: 0 40px;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        margin: 0 auto;
        background: #fff;
        position: relative;
    }
    .section_container::-webkit-scrollbar{
        width: 8px;
    }
    .section_container::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 4px;
    }
    .inner_container{
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
    }
    .section_container .child_item:not(:last-of-type){
        margin-bottom: 15px;
    }
    .section_container .guide_title {
        font-size: 22px;
        line-height: 35px;
        font-weight: 400;
        text-align: center;
        padding: 30px 15px 20px 15px;
        margin: 0;
        text-align: center;
    }

    .section_container h4.guide_title {
        font-size: 20px;
        line-height: 28px;
    }

    .section_container .guide_title span {
        font-size: 17px;
        line-height: 25px;
        display: block;
        margin-bottom: 5px;
    }

    .video_wpr {
        width: 100% !important;
        position: relative;
        padding-bottom: 56.25%;
        margin: 0;
    }

    .video_wpr :deep(iframe) {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        border-radius: 5px;
    }

    .step_bar2 {
        position: relative;
    }

    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #d9d9d9;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 12px;
        left: 1px;
        right: 1px;
        height: 3px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }
    .recommendation{
        font-size: 15px;
        line-height: 23px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: center;
        font-style: italic;
        padding-top: 15px;
        max-width: 900px;
        margin: 0 auto;
    }
    .action_area {
        display: flex;
        padding: 40px 0 20px 0;
        justify-content: center;
        gap: 10px;
        margin: 0 auto;
        max-width: 900px;
        align-items: flex-start;
    }

    .back_btn {
        padding: 12px 45px 12px 60px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        text-transform: uppercase;
        color: #121525;
        background: #fff;
        border: 1px solid #dbdde1;
        border-radius: 24px;
        display: block;
        cursor: pointer;
        position: relative;
        flex-grow: 0;
    }

    .back_btn i {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 15px;
    }

    .next_btn {
        padding: 12px 60px 12px 45px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        text-transform: uppercase;
        color: #fff;
        background: #2f7eed;
        border-radius: 24px;
        display: block;
        cursor: pointer;
        position: relative;
    }

    .next_btn i,
    .complete_btn i {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 15px;
    }

    .complete_btn {
        padding: 12px 60px 12px 45px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        text-transform: uppercase;
        color: #121525;
        background: #fff;
        border: 1px solid #dbdde1;
        border-radius: 24px;
        display: block;
        cursor: pointer;
        position: relative;
        flex-grow: 0;
    }
    .session_content{
        display: flex;
        flex-direction: column;
        height: 100vh;
        line-height: 0;
    }
    .session_content .frame_wpr{
        flex: 1 1 auto;
    }
    .frame_wpr iframe {
        height: 100%;
        /* height: 936px; */
    }

    .profile_status {
        padding: 20px 30px;
        /* border-left: 1px solid #e9e9e9; */
        background: #fff;
        width: 310px;
        max-height: 100vh;
        overflow-y: overlay;
    }

    .profile_status .time {
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 30px;
        text-align: center;
    }

    .profile_status::-webkit-scrollbar {
        display: none;
    }

    .profile_status h3 {
        font-size: 18px;
        line-height: 17px;
        font-weight: 500;
        color: #121525;
    }

    .profile_status h4 {
        font-size: 16px;
        line-height: 23px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 5px;
    }

    .profile_status h5 {
        font-size: 13px;
        line-height: 19px;
        font-weight: 300;
        color: #808080;
        margin: 15px 0;
    }

    .profile_status h6 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
        margin: 10px 0;
    }

    .profile_status p {
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        margin: 30px 0 0;
    }

    .profile_status ol {
        padding-left: 15px;
    }

    .profile_status ol li {
        list-style-type: decimal;
        font-size: 13px;
        line-height: 20px;
        padding: 6px 0;
    }

    .profile_status ul {
        padding-top: 10px;
    }

    .profile_status ul li {
        margin-bottom: 15px;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        padding: 15px;
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.02);
    }

    .profile_status ul li h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin: 0;
    }

    .profile_status ul li .cont {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 10px;
    }

    .profile_status ul li h6 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 6px 0;
        margin: 0;
    }

    .profile_status ul li h6 span {
        color: #2f7eed;
        font-weight: 500;
        padding-left: 5px;
    }

    .profile_status .score_circle {
        width: 160px;
        height: 160px;
        padding: 10px;
        margin: 0 auto 40px auto;
    }

    .profile_status .score_circle .inner_circle .score {
        font-size: 30px;
        line-height: 40px;
        font-weight: 500;
        color: #121525;
    }

    .profile_status .score_circle .inner_circle .score span {
        font-size: 20px;
    }

    .profile_status .score_circle h5 {
        font-size: 14px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        margin: 0;
    }

    .profile_status ul li .score_circle {
        width: 50px;
        height: 50px;
        padding: 4px;
        margin: 0;
    }

    .profile_status ul li .score_circle .inner_circle {
        background: #fff;
    }

    .profile_status ul li .score_circle .inner_circle .score {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
    }

    .profile_status ul li .score_circle .inner_circle .score span {
        font-size: 10px;
    }
    .profile_status .download_section{
        margin: 15px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }
    .profile_status .download_section img{
        max-width: 100%;
        height: auto;
        border-radius: 5px;
    }
    .profile_status .download_section a{
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #2f7eed;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        padding: 5px 0;
    }

    .score_circle svg circle {
        transition: all 0.3s ease-in-out;
    }

    .step_status {
        padding: 30px;
        border-bottom: 1px solid #e9e9e9;
    }

    .step_status .stat_bar {
        max-width: 650px;
        margin: 0 auto;
        display: flex;
        position: relative;
    }

    .step_status .stat_bar .bar {
        position: absolute;
        left: 5%;
        right: 5%;
        height: 6px;
        top: 2px;
        background: #e9e9e9;
        border-radius: 4px;
    }

    .step_status .stat_bar .bar span {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: #a1c8ff;
        transition: all 0.5s ease-in-out;
    }

    .step_status .stat_bar .point {
        display: flex;
        flex-grow: 1;
        justify-content: space-around;
    }

    .step_status .stat_bar .point span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid #2f7eed;
        position: relative;
        z-index: 1;
    }

    .scroll_content {
        max-height: calc(100vh - 105px);
        overflow-y: overlay;
    }

    .scroll_content .action_area {
        border-top: 1px solid #e9e9e9;
    }

    .scroll_content::-webkit-scrollbar {
        width: 4px;
    }

    .scroll_content::-webkit-scrollbar-thumb {
        background: #dbdbdb;
        border-radius: 2px;
    }

    .quest_list {
        padding: 15px 0 0;
        max-width: 550px;
        margin: 0 auto;
    }

    .quest_list h3 {
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
        color: #121525;
        padding: 20px 0;
        position: relative;
        display: flex;
        align-items: center;
    }

    .quest_list h3 span {
        width: 22px;
        height: 22px;
        background: #999;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        font-size: 11px;
        line-height: 16px;
        color: #fff;
    }

    .quest_wpr {
        text-align: left;
        padding: 30px 0;
        border-top: 1px solid #e9e9e9;
    }

    .quest_wpr h4 {
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        margin-bottom: 15px;
        color: #5a5a5a;
    }

    .quest_wpr ul li {
        padding: 7px 0;
    }

    .quest_wpr ul li .radio_fld {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }

    .quest_wpr ul li .radio_fld span {
        width: 14px;
        height: 14px;
        border: 1px solid #999;
        border-radius: 50%;
        position: relative;
        display: flex;
    }

    .quest_wpr ul li .radio_fld input[type="radio"] {
        display: none;
    }

    .quest_wpr ul li .radio_fld span:after {
        position: absolute;
        content: '';
        right: 2px;
        top: 2px;
        left: 2px;
        bottom: 2px;
        background: #5a5a5a;
        border-radius: 50%;
        opacity: 0;
    }

    .quest_wpr ul li .radio_fld input[type="radio"]:checked~span:after {
        opacity: 1;
    }

    .quest_wpr ul li .radio_fld h5 {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .quest_wpr input[type="text"] {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        padding: 0 15px;
        font-size: 13px;
        color: #5a5a5a;
        width: 100%;
        resize: none;
        height: 35px;
        display: none;
    }

    .quest_wpr ul li .radio_fld input[type="radio"]:checked~input[type="text"] {
        display: block;
    }

    .quest_wpr textarea {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        padding: 15px;
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        width: 100%;
        resize: none;
        height: 100px;
    }

    .hide {
        display: none;
    }

    a.close_btn {
        text-decoration: none;
        font-size: 13px;
    }

    .step_number {
        position: absolute;
        left: 40px;
        right: 40px;
        top: 37px;
        max-width: 600px;
        margin: 0 auto;
        padding: 0;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
    }

    .history {
        padding: 30px 0;
    }

    .history ul {
        display: block;
        gap: 10px;
    }

    .history ul li {
        border: 1px solid #e9e9e9;
        padding: 10px 15px;
        font-size: 11px;
        line-height: 16px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
    }

    :deep(.vff .f-container) {
        margin: 0;
        /* border-top: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9; */
    }

    :deep(.vff .f-container .f-form-wrap) {
        padding: 30px 0;
        max-width: 600px;
        margin: 0 auto;
    }

    :deep(.vff) {
        margin: 0 auto;
        padding: 100px 0 0;
    }

    :deep(.vff .fh2) {
        font-size: 25px;
        line-height: 35px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        padding: 0;
    }

    :deep(.vff ul.f-radios) {
        max-width: 300px;
    }

    :deep(.vff ul.f-radios li) {
        font-size: 16px;
        line-height: 25px;
        border-radius: 5px;
        font-weight: 400;
        cursor: pointer;
    }

    :deep(.vff-footer) {
        position: static;
    }

    :deep(.vff-footer .f-progress) {
        position: absolute;
        top: 35px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        font-size: 15px;
        color: #121525;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
    }

    :deep(.vff-footer .f-progress .f-progress-bar) {
        width: 100%;
        height: 6px;
        border-radius: 3px;
        margin: 10px 0 0 0;
    }

    :deep(.vff-footer .f-progress .f-progress-bar .f-progress-bar-inner) {
        height: 100%;
        border-radius: 3px;
    }

    :deep(.vff .f-enter) {
        margin: 0;
        position: absolute;
        left: 0;
        top: 100%;
        margin-top: 80px;
    }

    :deep(.vff-footer .footer-inner-wrap) {
        /* padding: 30px 15px; */
        padding: 0;
        background: transparent !important;
    }

    :deep(.vff .o-btn-action) {
        border-radius: 5px;
        font-size: 18px;
    }

    :deep(.vff .f-enter-desc) {
        font-size: 16px;
        font-weight: 500;
    }

    :deep(.field-submittype) {
        text-align: center;
    }

    :deep(.field-submittype .o-btn-action),
    :deep(.field-submittype .f-enter-desc) {
        display: none;
    }

    .loom-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .progress-circle {
        margin: 0 auto 20px auto;
        padding: 10px;
        justify-content: center;
        display: flex;
    }

    .thrive-score {
        height: calc(100vh - 130px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
    }
    .thrive-score h2{
        font-weight: 400;
        font-size: 22px;
        line-height: 34px;
        text-align: center;
    }

    .score-board {
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    .score-board h3, .score-board h6, .score-board h5 {
        color: #FFF;
    }

    .score-board h6 {
        font-size: 13px;
        line-height: 20px;
    }
    .score-board h5 {
        font-size: 12px;
        line-height: 18px;
    }

    .button-with-info {
        text-align: center;
    }

    .button-with-info small {
        margin-top: 10px;
        color: #808080;
    }

    .score-wrapper {
        margin-bottom: 40px;
        columns: 2;
        /* background: #fff; */
        /* border-radius: 5px; */
        /* border: 1px solid #e9e9e9; */
        /* padding: 15px; */
        /* box-shadow: 0 1px 15px rgb(0 0 0 / 2%); */
    }

    .score-wrapper .score-card {
        display: flex;
        align-items: center;
    }

    .score-wrapper .score-card + .score-card {
        margin-top: 12px;
    }

    .score-wrapper .score-card .score-swatches {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin-right: 7px;
    }

    .score-wrapper .score-card span {
        font-size: 11px;
        line-height: 16px;
    }

    ol li a {
        text-decoration: none;
        /* color: inherit; */
    }

    ol li a:hover {
        color: inherit;
    }

    ol li a:visited, ol li a:hover, ol li a:active {
        /* color: inherit; */
    }

    .preview_area {
        -webkit-animation: none;
        animation: none;
    }

    .setup_link {
        color: #2b80ee;
    }
    .global_setting .tabs_content .content_area{
        padding: 0 30px;
    }
    .global_setting .stat_bar {
        display: none;
        position: fixed;
        right: 18px;
        top: 50px;
        line-height: 0;
        z-index: 4;
        transition: all .3s ease-in-out;
        cursor: pointer;
    }
    .global_setting .stat_bar img{
        width: 20px;
        height: auto;
    }
    .profile_status .close {
        position: absolute;
        right: 15px;
        top: 10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #f5f5f5;
        color: #999;
        font-size: 13px;
        z-index: 1;
        display: none;
        align-items: center;
        justify-content: center;
    }

    .global_setting button.toggle_bar{
        position: fixed;
        right: 20px;
    }

    .toolbar .close_btn {
        padding: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #dfdfdf;
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 15px;
        top: 10px;
        z-index: 9;
        cursor: pointer;
    }
    .toolbar .close_btn img {
        max-width: 14px;
        height: auto;
        transform: scaleX(-1);
    }

    @media(max-width: 1199px){
        .global_setting .tabs_content .content_area{
            padding: 0;
        }
        .global_setting .toolbar {
            left: -270px;
            position: absolute;
            height: 100vh;
            top: 0;
            z-index: 6;
            width: 270px;
            padding: 50px 15px 30px 15px;
            box-shadow: 0 0 15px rgba(0,0,0,0.05);
            margin: 0;
            min-width: auto;
            transition: all .3s ease-in-out;
        }
        .global_setting.show_m_sidebar .toolbar {
            left: 0;
        }
        .global_setting .stat_bar, .profile_status .close{
            display: block;
        }
        .profile_status{
            position: fixed;
            background: #fff;
            box-shadow: 0 0 15px rgba(0,0,0,0.05);
            top: 0;
            right: -310px;
            height: 100vh;
            transition: all .3s ease-in-out;
            z-index: 10;
        }
        .profile_status.show{
            right: 0;
        }
        .toolbar .close_btn {
            display: flex;
        }
    }
    @media(max-width: 797px){
        .section_container .guide_title{
            padding-bottom: 30px;
        }
    }
    @media(max-width: 767px){
        .section_container h4.guide_title{
            font-size: 18px;
            line-height: 28px;
        }
        :deep(.vff-footer .f-progress), .step_number {
            max-width: 100%;
            padding: 0 5.5%;
            font-size: 13px;
            line-height: 20px;
        }
        .step_number {
            left: 20px;
            right: 20px;
        }
        :deep(.vff .fh2) {
            font-size: 20px;
            line-height: 32px;
        }
        .section_container{
            padding: 0 30px;
        }
    }
    @media(max-width: 499px){
        /* .global_setting .tabs_content .content_area{
            padding: 0;
        } */
        .section_container{
            padding: 0 30px;
        }
        .section_container h4.guide_title{
            font-size: 16px;
            line-height: 23px;
        }
        .thrive-score h2{
            font-size: 17px;
            line-height: 27px;
        }
        .recommendation{
            font-size: 13px;
            line-height: 20px;
        }
        .light_bg .stat_bar{
            display: none;
        }
    }
</style>
